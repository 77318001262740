module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Balenciaga Spring 22","short_name":"Balenciaga Spring 22","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","orientation":"portrait-primary","lang":"fr","crossOrigin":"use-credentials","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9a837b279cd5b132ef21c04e207c4297"},
    },{
      plugin: require('../node_modules/gatsby-plugin-jss-provider/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/configuration/jss","minify":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/buddy/balenciaga-spring22-frontend/src/intl/messages","languages":["zh"],"defaultLanguage":"zh","redirect":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
