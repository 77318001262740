// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-containers-homepage-index-js": () => import("./../../../src/containers/Homepage/index.js" /* webpackChunkName: "component---src-containers-homepage-index-js" */),
  "component---src-containers-looks-index-js": () => import("./../../../src/containers/Looks/index.js" /* webpackChunkName: "component---src-containers-looks-index-js" */),
  "component---src-containers-not-found-index-js": () => import("./../../../src/containers/NotFound/index.js" /* webpackChunkName: "component---src-containers-not-found-index-js" */),
  "component---src-containers-page-index-js": () => import("./../../../src/containers/Page/index.js" /* webpackChunkName: "component---src-containers-page-index-js" */)
}

