const maxRules = 1e10

function createGenerateId(options) {
  let ruleCounter = 0

  return (rule) => {
    ruleCounter += 1

    if (ruleCounter > maxRules) {
      throw new Error(`[JSS] You might have a memory leak. Rule counter is at ${ruleCounter}.`)
    }

    if (options.minify) {
      // Using "c" because a number can't be the first char in a class name.
      // return `c-${ruleCounter}`
    }

    return `${rule.key}-${ruleCounter}`
  }
}

module.exports = (jss) => {
  jss.setup({
    createGenerateId,
  })

  return {
    jss,
    generateId: jss.generateId,
  }
}
